<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialogVersao" scrollable persistent>
			<v-card max-width="575">
				<v-card-title>Adicionar nova versão</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row no-gutters>
						<v-col cols="4">
							<v-text-field
								v-model="versao.sistema"
								class="mb-n5 mt-7 mr-1"
								outlined
								dense
								type="number"
								label="Sistema"
								required
							></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="versao.modulo"
								class="mb-n5 mt-7 mx-1"
								outlined
								dense
								type="number"
								label="Módulo"
								required
							></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="versao.correcao"
								class="mb-n5 mt-7 ml-1"
								outlined
								dense
								type="number"
								label="Correção"
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="red" dark elevation="0" class="px-10" @click="dialogVersao = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" class="px-10" @click="adicionarVersao()">Adicionar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogNota" scrollable persistent>
			<v-card max-width="575">
				<v-card-title>Adicionar nova nota</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row no-gutters>
						<v-col cols="12">
							<v-text-field v-model="nota.nota" class="mb-n4 mt-7" outlined dense label="Título" required></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								v-model="nota.descricao"
								class="mb-n5"
								rows="2"
								outlined
								label="Descrição"
								required
							></v-textarea>
						</v-col>
						<v-col cols="6">
							<v-select
								v-model="nota.idtipo"
								:items="tipoNotas"
								item-value="id"
								item-text="value"
								class="mb-n5 mr-1"
								outlined
								dense
								label="Tipo"
								required
							></v-select>
						</v-col>
						<v-col cols="6">
							<v-select
								v-model="nota.idversao"
								:items="versoes.lista"
								item-text="versao"
								item-value="idversao"
								class="mb-n5 ml-1"
								outlined
								dense
								label="Versão"
								required
							></v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="red" dark elevation="0" class="px-10" @click="dialogNota = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" class="px-10" @click="adicionarNota()">Adicionar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12">
			<v-card>
				<v-card-text class="text-center">
					<v-row>
						<v-col>
							<v-select
								class="mb-n7"
								dense
								:items="versoes.lista"
								item-text="versao"
								item-value="idversao"
								label="Versão do sistema"
								v-model="idversao"
								@change="listarNotas()"
								outlined
							></v-select>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					Notas da versão
					<v-spacer />
					<v-btn fab x-small elevation="0" color="primary" @click="dialogVersao = true">
						<v-icon>mdi-alpha-v-circle-outline</v-icon>
					</v-btn>
					<v-btn class="ml-2" fab x-small elevation="0" color="primary" @click="dialogNota = true">
						<v-icon>mdi-alpha-n-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text v-if="notas.lista">
					<v-list v-if="notas.lista.length > 0">
						<span
							class="font-italic"
							v-if="notas.lista.filter(v => {return v.idtipo == 1}).length > 0"
						>Correção de bugs</span>
						<v-list-item
							two-line
							v-for="u in notas.lista.filter(v => {return v.idtipo == 1})"
							:key="u.idnota"
						>
							<v-list-item-content>
								<v-list-item-title class="py-1 font-weight-bold">{{u.dataalteracao}} - {{u.nota}}</v-list-item-title>
								<v-list-item-subtitle class="ml-5">{{u.descricao}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<span
							class="font-italic"
							v-if="notas.lista.filter(v => {return v.idtipo == 2}).length > 0"
						>Novidades</span>
						<v-list-item
							two-line
							v-for="u in notas.lista.filter(v => {return v.idtipo == 2})"
							:key="u.idnota"
						>
							<v-list-item-content>
								<v-list-item-title class="py-1 font-weight-bold">{{u.dataalteracao}} - {{u.nota}}</v-list-item-title>
								<v-list-item-subtitle class="ml-5">{{u.descricao}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<span
							class="font-italic"
							v-if="notas.lista.filter(v => {return v.idtipo != 1 && v.idtipo != 2}).length > 0"
						>Outros</span>
						<v-list-item
							two-line
							v-for="u in notas.lista.filter(v => {return v.idtipo != 1 && v.idtipo != 2})"
							:key="u.idnota"
						>
							<v-list-item-content>
								<v-list-item-title class="py-1 font-weight-bold">{{u.dataalteracao}} - {{u.nota}}</v-list-item-title>
								<v-list-item-subtitle class="ml-5">{{u.descricao}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-card-text v-else class="text-center text-h6 pb-8 pt-5">
					<v-row>
						<v-col cols="12" class="mb-n5">
							<v-icon size="100">mdi-alert-circle-outline</v-icon>
						</v-col>
						<v-col cols="12">Nenhum registro encontrado.</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "TiNotasDeVersao",
	components: { CardDialog },
	data: () => ({
		versoes: {},
		notas: {},
		idversao: 0,
		dialogVersao: false,
		dialogNota: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		tipoNotas: [
			{ id: 1, value: "Correção de bugs" },
			{ id: 2, value: "Novidades" },
			{ id: 3, value: "Outros" },
		],
		nota: { nota: "", descricao: "", idtipo: 0, idversao: 0 },
		versao: { sistema: 0, modulo: 0, correcao: 0 },
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		limpar() {
			this.nota = { nota: "", descricao: "", idtipo: 0, idversao: 0 };
		},
		listarVersoes() {
			return axios.post(`${this.backendUrl}ti/versao`).then((res) => {
				this.versoes = res.data;
				this.idversao = this.versoes.lista[0].idversao;
				this.versao.sistema =
					this.versoes.lista[0].versao.split(".")[0];
				this.versao.modulo = this.versoes.lista[0].versao.split(".")[1];
				this.versao.correcao =
					this.versoes.lista[0].versao.split(".")[2];
				this.listarNotas();
			});
		},
		listarNotas() {
			return axios
				.post(`${this.backendUrl}ti/notas-versao`, {
					idversao: this.idversao,
				})
				.then((res) => {
					this.notas = res.data;
				});
		},
		adicionarNota() {
			if (
				!this.nota.nota ||
				!this.nota.descricao ||
				!this.nota.idtipo ||
				!this.nota.idversao
			) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Um ou mais campos não foram preenchidos!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			return axios
				.post(`${this.backendUrl}ti/notas-versao/cadastrar`, this.nota)
				.then((res) => {
					this.dialogNota = false;
					this.limpar();
					if (res.data) {
						this.dialogErro.title = "Cadastro efetuado";
						this.dialogErro.msg =
							"Cadastro da nota de versão realizado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.listarVersoes();
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível realizar o cadastro da nota de versão!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogVersao = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível realizar o cadastro da nota de versão!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		adicionarVersao() {
			if (
				!this.versao.sistema ||
				!this.versao.modulo ||
				!this.versao.correcao
			) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Um ou mais campos não foram preenchidos!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			return axios
				.post(`${this.backendUrl}ti/versao/cadastrar`, {
					versao: `${this.versao.sistema}.${this.versao.modulo}.${this.versao.correcao}`,
				})
				.then((res) => {
					this.dialogVersao = false;
					if (res.data) {
						this.dialogErro.title = "Cadastro efetuado";
						this.dialogErro.msg =
							"Cadastro de versão realizado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.listarVersoes();
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível realizar o cadastro de versão!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogVersao = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível realizar o cadastro de versão!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
	},
	created() {
		this.listarVersoes();
	},
};
</script>